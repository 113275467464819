import type {
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";

import { useId } from "@react-aria/utils";
import clsx from "clsx";
import { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

let fieldLabelIdContext = createContext<string | undefined>(void 0);
let fieldHintIdContext = createContext<string | undefined>(void 0);
let fieldDatalistIdContext = createContext<string | undefined>(void 0);

type ValidateEvent = { value: string };
type ValidateResult = string;
/**
 * Validate if an input is valid.
 * @returns {string} The error message if the input is invalid, otherwise an empty string
 */
type ValidateEventHandler = (event: ValidateEvent) => ValidateResult;

export function Field({ children }: { children: ReactNode }) {
  let id = useId();
  let hintId = useId();
  let datalistId = useId();
  return (
    <fieldLabelIdContext.Provider value={id}>
      <fieldHintIdContext.Provider value={hintId}>
        <fieldDatalistIdContext.Provider value={datalistId}>
          <div className="flex flex-col gap-2">{children}</div>
        </fieldDatalistIdContext.Provider>
      </fieldHintIdContext.Provider>
    </fieldLabelIdContext.Provider>
  );
}

Field.Label = Label;
Field.Hint = Hint;
Field.Input = Input;
Field.Textarea = Textarea;
Field.Datalist = Datalist;
Field.Select = Select;
Field.CashInput = CashInput;

function Label({ children }: { children: ReactNode }) {
  let id = useContext(fieldLabelIdContext);
  return (
    <label
      className="text-xs leading-4 font-medium tracking-wider uppercase text-neutral-900"
      htmlFor={id}
    >
      {children}
    </label>
  );
}

function Hint({ children }: { children: ReactNode }) {
  let id = useContext(fieldHintIdContext);
  return (
    <p id={id} className="text-sm leading-none font-normal text-neutral-600">
      {children}
    </p>
  );
}

function Input({
  onValidate,
  ...props
}: Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "className" | "id" | "aria-describedby" | "onBlur"
> & { onValidate?: ValidateEventHandler }) {
  let id = useContext(fieldLabelIdContext);
  let hintId = useContext(fieldHintIdContext);
  let datalistId = useContext(fieldDatalistIdContext);

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    let $input = event.currentTarget;
    if (!onValidate) return;
    $input.setCustomValidity(onValidate({ value: $input.value }));
    $input.reportValidity();
  }

  return (
    <input
      id={id}
      className={clsx(
        "light:enabled:bg-white dark:enabled:bg-neutral-300",
        "py-[9px] px-[13px] w-full",
        "text-base leading-6 font-normal",
        "text-neutral-900 placeholder-shown:text-neutral-600",
        "border border-gray-300 rounded-md shadow-sm",
        "focus:outline-none focus:ring-1 focus:ring-accentTeal-600 focus:border-accentTeal-600",
        "actually-invalid:border-failure-900 actually-invalid:ring-failure-900",
        "disabled:bg-neutral-100",
      )}
      aria-describedby={hintId}
      list={datalistId}
      onBlur={handleBlur}
      {...props}
    />
  );
}

function Textarea({
  onValidate,
  ...props
}: Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "className" | "id" | "aria-describedby" | "onBlur"
> & { onValidate?: ValidateEventHandler }) {
  let id = useContext(fieldLabelIdContext);
  let hintId = useContext(fieldHintIdContext);

  function handleBlur(event: FocusEvent<HTMLTextAreaElement>) {
    let $input = event.currentTarget;
    if (!onValidate) return;
    $input.setCustomValidity(onValidate({ value: $input.value }));
    $input.reportValidity();
  }

  return (
    <textarea
      id={id}
      className={clsx(
        "py-[9px] px-[13px] w-full",
        "text-base leading-6 font-normal",
        "light:bg-white dark:bg-neutral-300",
        "text-neutral-900 placeholder-shown:text-neutral-600",
        "border border-gray-300 rounded-md shadow-sm",
        "focus:outline-none focus:ring-1 focus:ring-accentTeal-600 focus:border-accentTeal-600",
        "actually-invalid:border-failure-900 actually-invalid:ring-failure-900",
      )}
      aria-describedby={hintId}
      onBlur={handleBlur}
      {...props}
    />
  );
}

function Datalist({ children }: { children: ReactNode }) {
  let id = useContext(fieldDatalistIdContext);
  return <datalist id={id}>{children}</datalist>;
}

function Select({
  children,
  ...props
}: SelectHTMLAttributes<HTMLSelectElement> & { children: ReactNode }) {
  let id = useContext(fieldLabelIdContext);
  let hintId = useContext(fieldHintIdContext);

  return (
    <select
      id={id}
      {...props}
      className="border shadow-sm bg-white border-neutral-300 text-neutral-800 focus:border-neutral-800 w-full focus:outline-none focus:ring-0 rounded-md"
      aria-describedby={hintId}
    >
      {children}
    </select>
  );
}

function CashInput({
  hidePencil = false,
  ...props
}: Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "className" | "id" | "aria-describedby" | "onBlur"
> & { hidePencil?: boolean }) {
  let { t } = useTranslation();
  let currency = useId();
  let id = useContext(fieldLabelIdContext);
  let hintId = useContext(fieldHintIdContext);
  let datalistId = useContext(fieldDatalistIdContext);

  return (
    <>
      <div className="relative flex items-center justify-start">
        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none select-none right-0">
          <span className="text-3xl leading-9 text-neutral-900">$</span>
          <span className="sr-only" id={currency}>
            {t("Currency USD")}
          </span>
        </div>
        <input
          placeholder="0.00"
          step="any"
          {...props}
          type="number"
          id={id}
          aria-describedby={`${hintId} ${currency}`}
          list={datalistId}
          className={clsx(
            "pr-16 py-5 pl-9 w-full",
            "text-3xl leading-9 font-normal",
            "light:bg-white dark:bg-neutral-300",
            "text-neutral-900 placeholder-shown:text-neutral-600",
            "border border-gray-300 rounded-md shadow-sm",
            "focus:outline-none focus:ring-1 focus:ring-accentTeal-600 focus:border-accentTeal-600",
            "actually-invalid:border-failure-900 actually-invalid:ring-failure-900",
            { "pr-16": !hidePencil, "pr-6": hidePencil },
          )}
        />
        {hidePencil ? null : (
          <div className="absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none">
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-neutral-quaternary"
            >
              <path
                d="M18.2677 2.71441L18.9748 2.0073V2.0073L18.2677 2.71441ZM4.5 20.0177V21.0177C4.76522 21.0177 5.01957 20.9123 5.20711 20.7248L4.5 20.0177ZM1 20.0177H0C0 20.5699 0.447715 21.0177 1 21.0177L1 20.0177ZM1 16.4466L0.292893 15.7395C0.105357 15.927 0 16.1814 0 16.4466H1ZM15.4393 3.42152C16.0251 2.83573 16.9748 2.83573 17.5606 3.42152L18.9748 2.0073C17.608 0.640469 15.3919 0.640469 14.0251 2.0073L15.4393 3.42152ZM17.5606 3.42152C18.1464 4.0073 18.1464 4.95705 17.5606 5.54284L18.9748 6.95705C20.3417 5.59022 20.3417 3.37414 18.9748 2.0073L17.5606 3.42152ZM17.5606 5.54284L3.79289 19.3105L5.20711 20.7248L18.9748 6.95705L17.5606 5.54284ZM4.5 19.0177H1V21.0177H4.5V19.0177ZM14.0251 2.0073L0.292893 15.7395L1.70711 17.1537L15.4393 3.42152L14.0251 2.0073ZM0 16.4466V20.0177H2V16.4466H0ZM12.5251 4.92152L16.0606 8.45705L17.4748 7.04284L13.9393 3.5073L12.5251 4.92152Z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
}
